@import url(https://fonts.googleapis.com/css?family=Rubik:300,regular,500,600,700,800,900,300italic,italic,500italic,600italic,700italic,800italic,900italic);

* {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  background: royalblue;
  color: white;
}

.container {
  padding: 1% 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container form {
  display: flex;
}

.container form input,
button {
  padding: 10px 12px;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 5px;
  border: 1px solid rgba(107, 107, 107, 0.5);
}

button {
  border: none;
}

.container .image-container {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 10px;
}

.loading-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* background: blur(10); */
  color: white;
  backdrop-filter: blur(15px);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.options-btn {
  background-color: rgba(128, 128, 128, 0.445);
  font-size: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  top: 40px;
  right: 100%;
  left: 0;
  float: right;
}

.options-btn:hover {
  background-color: rgba(128, 128, 128, 0.65);
}

@media (max-width: 800px) {
  .container .image-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 650px) {
  .container .image-container {
    grid-template-columns: 1fr 1fr;
  }
}
